import router from '../router'
import store from '../store/store'

export default {
  methods: {
    userGroupContains(groupsList) {
      var userGroups = store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          store.dispatch('AUTH_LOGOUT').then(() => {
            router.push('/se-connecter')
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    }
  }
}

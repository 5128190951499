<template>
  <div class="Inventaire" >
    <validationCartouche
      :hasCedantValidated="inventaireActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="inventaireActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Inventaire du stock </h2>


      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="inventaireActiviteCedee.has_repreneur_validated && inventaireActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>

      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="inventaireActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="inventaireActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyInventaire"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="inventaireActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="inventaireActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{inventaireActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>,
        il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>


      <h3 class="gavc-h3 gavc-text-green mt-4"> Inventaire du stock réalisé par les parties ou par un huissier</h3>

      <div class="gavc-field mt-3">
        <label class="gavc-label" for="inventaireActiviteCedee.inventaire_date-input">
          Date de l'inventaire
          <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-field gavc-field--icon">
          <input
            @input="$v.inventaireActiviteCedee.inventaire_date.$touch()"
            class="gavc-input gavc-input--icon gavc-input--calendar"
            id="inventaireActiviteCedee.inventaire_date-input"
            type="date"
            v-model="inventaireActiviteCedee.inventaire_date"
            :disabled="!cedantCanModified"
          >
          <i class="gavc-icon gavc-icon-calendar" ></i>
        </div>
      </div>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.inventaireActiviteCedee.inventaire_value.$dirty ? $v.inventaireActiviteCedee.inventaire_value.$error : null  }">
        <label class="gavc-label" for="inventaireActiviteCedee.inventaire_value-group">
            Valeur estimée de l'inventaire
            <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-field gavc-field--icon">
          <numberMoneyInput
            id="inventaireActiviteCedee.inventaire_value-group"
            :maxLength="'10'"
            :maskedOptions="maskedMontantOptions"
            :value="inventaireActiviteCedee.inventaire_value"
            @value-changed="inventaireActiviteCedee.inventaire_value = $event;"
            @input="$v.inventaireActiviteCedee.inventaire_value.$touch()"
            @changed="modifyInventaire"
            :disabled="!cedantCanModified"
          />
          <i class="gavc-icon gavc-icon-euro" ></i>
        </div>
      </div>

      <p class="mt-4">
        Documents à charger : <strong>Inventaire réalisé</strong>
      </p>

      <auditItem
        :disabled="!cedantCanModified"
        @document-deleted="deleteAuditItem($event, 'inventaireActiviteCedee')"
        @document-download="downloadAuditDocument($event, 'inventaireActiviteCedee')"
        @document-uploaded="createAuditDocument($event, 'INVENTAIRE', inventaireActiviteCedee.id, 'inventaireActiviteCedee')"
        @item-validated="validateAuditItem($event, 'inventaireActiviteCedee', true)"
        @item-unvalidated="validateAuditItem($event, 'inventaireActiviteCedee', false)"
        :items="filterDocuments('INVENTAIRE', 'CEDANT')"
        @justificatif-added="createAuditItemWithoutDocument($event, 'INVENTAIRE', inventaireActiviteCedee.id, 'inventaireActiviteCedee')"
        type="INVENTAIRE"
      />

      <div v-if="!$v.inventaireActiviteCedee.$invalid">
        <!-- ######## CEDANT BUTTONS ########## -->
        <div v-if="!inventaireActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
          <div class="col-12 text-right">
            <validationSectionButton
              @modify="modifyInventaire"
              @validate="validateInventaire(true)"
            />
          </div>
        </div>
        <div v-else-if="inventaireActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
          <div class="col-12 text-right">
            <button
              class="gavc-btn gavc-btn--bone"
              @click="validateInventaire(false)"
            >
                Invalider cette étape
            </button>
          </div>
        </div>

        <div v-if="!inventaireActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
          <div class="col-12 text-right">
            <validationSectionButton
              @validate="validateInventaire(true)"
              :withModifyButton="false"
            />
          </div>
        </div>
        <div v-else-if="inventaireActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
          <div class="col-12 text-right">
            <button
              class="gavc-btn gavc-btn--bone"
              @click="validateInventaire(false)"
            >
                Invalider cette étape
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="inventaireActiviteCedee.has_cedant_validated"
            text="Le cédant a validé toute la section"
          />
        </div>
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="inventaireActiviteCedee.has_repreneur_validated"
            text="Le repreneur a validé toute la section"
          />
        </div>
      </div>

      <div class="mt-4 text-center" v-if="inventaireActiviteCedee.has_repreneur_validated && inventaireActiviteCedee.has_cedant_validated">
        <button
          class="gavc-btn"
          @click="$emit('next-step')">
              Étape validée - Accéder à l'étape suivante
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { numeric, minValue, maxValue, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import { isDate } from '@/validators/validators'
import { defaultMoneyMaskOptions } from "@/variables/masks/moneyMasks"
import auditItemsMixins from '@/mixins/auditItemsMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const numberMoneyInput = () => import('@/components/inputs/numberMoneyInput')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')


export default {
  name: 'Inventaire',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    numberMoneyInput,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingInventaire: false,
      inventaireActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        inventaire_date: null,
        inventaire_value: null
      },

      maskedMontantOptions: defaultMoneyMaskOptions,
    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.inventaireActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.getInventaire()
    this.scrollToTop()
  },

  validations: {
    inventaireActiviteCedee: {
      inventaire_date: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      inventaire_value: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000000),
      }
    }
  },
  methods: {
    filterDocuments (type, uploader) {
      return this.inventaireActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    documentModified () {
      this.getInventaire()
    },
    getInventaire () {
      this.isGettingInventaire = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'inventaireActiviteCedee', this.id)
      .then((result) => {
        this.inventaireActiviteCedee = result.data
        this.inventaireActiviteCedee.inventaire_value = Number(this.inventaireActiviteCedee.inventaire_value)
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('inventaireActiviteCedee getInventaire API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingInventaire = false
      })
    },

    modifyInventaire () {
      if (this.inventaireActiviteCedee.nb_salaries === "") {
        return
      }
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'inventaireActiviteCedee', this.id, this.inventaireActiviteCedee)
      .then((result) => {
        this.inventaireActiviteCedee = result.data
        this.inventaireActiviteCedee.inventaire_value = Number(this.inventaireActiviteCedee.inventaire_value)
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('inventaireActiviteCedee modifyInventaire API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateInventaire (validationStatus) {
      const data = {...this.inventaireActiviteCedee}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "inventaireActiviteCedee",  this.id, data)
      .then((result) => {
        this.inventaireActiviteCedee = result.data
        this.inventaireActiviteCedee.inventaire_value = Number(this.inventaireActiviteCedee.inventaire_value)
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
        else {
            this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('inventaireActiviteCedee validateInventaire API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>

import { APIConnected } from '@/api/APIConnected'

const apiConnectedService = new APIConnected()

export default {
  methods: {
    createAuditDocument (addingDocument, documentType, sectionId, sectionType) {
      this.$store.commit('loadFromAPI')
      var formData = new FormData()
      formData.append('file', addingDocument.target.files[0])
      formData.append('type', documentType)
      formData.append('section', sectionId)
      formData.append('has_file', true)

      apiConnectedService.createAuditItem(
        this.token, sectionType, formData)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast('Votre document a bien été ajouté', {
            id: 'notification-toast',
            title: 'Documents ajouté',
            variant: 'success',
            solid: true
          })

          this.documentModified()
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit createAuditDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    createAuditItemWithoutDocument (comment, documentType, sectionId, sectionType) {
      this.$store.commit('loadFromAPI')
      var formData = new FormData()
      formData.append('comment', comment.target.value)
      formData.append('type', documentType)
      formData.append('section', sectionId)
      formData.append('has_file', false)

      apiConnectedService.createAuditItem(
        this.token, sectionType, formData)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast('Votre justificatif a bien été ajouté', {
            id: 'notification-toast',
            title: 'Justificatif ajouté',
            variant: 'success',
            solid: true
          })

          this.documentModified()
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit createAuditDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

    downloadAuditDocument (item, sectionType) {
      apiConnectedService.downloadAuditDocument(this.token, sectionType, item.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', item.name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

    deleteAuditItem (deleteAuditItemId, sectionType) {
      this.$store.commit('loadFromAPI')
      apiConnectedService.deleteAuditItem(
        this.token, sectionType, deleteAuditItemId)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast('Votre document / justificatif a bien été supprimé', {
            id: 'notification-toast',
            title: 'Suppression réussie',
            variant: 'danger',
            solid: true
          })

          this.documentModified()

        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit deleteAuditItem API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },


    validateAuditItem (sectionId, sectionType, isValidated) {
      this.$store.commit('loadFromAPI')
      var formData = new FormData()
      formData.append('is_validated', isValidated)

      apiConnectedService.validateAuditItem(
        this.token, sectionType, sectionId, formData)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }

          if (isValidated) {
            this.$bvToast.hide('notification-toast')
            this.$bvToast.toast('Vous avez bien validé le document', {
              id: 'notification-toast',
              title: 'Documents validé',
              variant: 'success',
              solid: true
            })
          }
          else {
              this.$bvToast.hide('notification-toast')
            this.$bvToast.toast('Vous avez bien invalidé le document', {
              id: 'notification-toast',
              title: 'Documents invalidé',
              variant: 'dander',
              solid: true
            })
          }

          this.documentModified()
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit validateAuditItem API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },

  }
}
